import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/woordenlijst/"> Woordenlijst diarree </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-108">
            <h1>Flatulentie of winderigheid</h1>
            <p>
              Overmatige gasvorming in de darmen. 14 keer gas uitdrijven in een
              periode van 24 uur wordt als ‘normaal’ beschouwd.
            </p>
            <a className="button radius" href="/woordenlijst/">
              volledige woordenlijst{" "}
            </a>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
